<template>
  <div class="hello">
    <section class="banner home">
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 h-full">
        <div class="flex flex-col justify-center items-center p-5 items-center justify-center h-screen">
          <div class="max-w-md text-center w-full">
            <h2 class="text-2xl text-center font-semibold">Reset Password</h2>
            <InputText
              class="password appearance-none border border-black w-full p-mt-3 px-3 mt-6 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="password"
              name="password1"
              ref="password1"
              v-validate="'required|strong_password'"
              v-model="password"
              data-vv-as="password"
              placeholder="Your new password" />
            <span class="block text-red-500 italic text-left">{{ errors.first('password1') }}</span>

            <InputText
              class="password appearance-none border border-black w-full p-mt-3 px-3 mt-6 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="password"
              name="password2"
              v-validate="'required|confirmed:password1'"
              v-model="passwordTwo"
              data-vv-as="password"
              placeholder="Password confirmation" />
            <span class="block text-red-500 italic text-left">{{ errors.first('password2') }}</span>
            <button
              :disabled="errors.items.length > 0"
              :class="{ 'opacity-50 cursor-not-allowed': errors.items.length > 0 }"
              class="p-button p-button-success w-full p-mt-3"
              @click.prevent="resetPassword">
              Confirm your new password
            </button>
            <Divider />
            <button class="py-3 text-base text-blue-500" type="button" @click.prevent="$emit('login')">Return to log in</button>
          </div>
        </div>
        <img src="@/assets/images/change-password.jpeg" style="height: 100vh" class="object-cover object-top hidden lg:block" />
      </div>
    </section>
  </div>
</template>

<script>
import auth_service from '@/services/auth_service';
import InputText from 'primevue/inputtext';
import Divider from 'primevue/divider';

export default {
  name: 'ContactUs',
  data() {
    return {
      password: null,
      passwordTwo: null,
    };
  },
  components: {
    InputText,
    Divider
  },
  methods: {
    resetPassword() {
      auth_service
        .resetPassword(this.$route.params.id, this.password)
        .then(({ data }) => {
          if (data.status) {
            this.$msg.success({ title: 'Password Reset', message: 'Password has been successfully reset' });
            this.$router.push({ name: 'home_page' });
          } else {
            this.$msg.error({ title: 'Password Reset', message: 'Password reset failed' });
          }
        })
        .catch(({ response }) => this.$msg.error({ title: 'Password Reset', message: response.data.error }));
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
// @import "~@/scss/variables.scss";
</style>
